<template>
  <div class="timer">
    <button v-if="!timerRunning" @click="startTimer('initial')" :disabled="timerRunning">
      Start Timer
    </button>
    <button v-if="timerRunning" @click="pauseTimer" :style="{background: 'orange'}">
      {{ timerPaused ? "Continue Timer" : "Pause Timer" }}
    </button>
    <button v-if="timerRunning" @click="stopTimer" :disabled="!timerRunning" :style="{background: 'red'}">
      Stop Timer
    </button>
    <div class="textTime">
       <label class="timeLabelMins" for="mins">mins</label> 
        <input 
        id="mins"
        class="timerTextMins"
        size="2"
        maxlength="2"
        type="text"
        :value="timeLeftMinutes"
        :disabled="isInputDisabled"
        :style="{color: backgroundColor}"
        @keypress="validateIsNumber"
        @change="updateInitialTime($event, 'mins')"
      />
      
      <span class="timerText" :style="{color: backgroundColor}">:</span>
        <input
        id="secs"
        class="timerTextSecs"
        type="text"
        size="2"
        maxlength="2"
        :value="timeLeftSeconds"
        :disabled="isInputDisabled"
        :style="{color: backgroundColor}"
        @keypress="validateIsNumber"
        @change="updateInitialTime($event, 'secs')"
      /><label for="secs" class="timeLabelSecs">secs</label>
    </div>
    <div class="visualTimerBorder">
      <div
        class="visualTimer"
        :style="{ width: percentageLeft + '%', background: backgroundColor }"
        @click="isPercentageTextShowing=!isPercentageTextShowing"
      >
        <span v-if="isPercentageTextShowing" class="visualText">{{percentageLeftText}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lastClockTime: 0,
      initialTimeSeconds: 0,
      initialTimeMinutes: 5,
      initialTime: 5*60*1000,
      initialSeconds: 30,
      initialMinutes: 0,
      timeLeft: 5*60*1000,
      intervalId: 0,
      timerRunning: false,
      timerPaused: false,
      audio: null,
      backgroundColor: "blue",
      isPercentageTextShowing: true,
      audioURL: './timeupstoppingpoint.mp3',
      audioRepeateTimes: 3
    };
  },
  methods: {
    startTimer(type) {
      if (!this.timerRunning || this.timerPaused) {
        if (type === "initial") {
            this.timeLeft = this.initialTime;
            const initialTime = new Date();
            this.lastClockTime = initialTime.getTime();
        }
        else if(type === "restart"){
          //coming out of a pause
          this.lastClockTime = (new Date()).getTime();
        }
        this.timerRunning = true;
        this.intervalId = setInterval(() => {
          //this.timeLeft = this.timeLeft - 1000;
          const currentTime = (new Date()).getTime();
          const timePassed = Math.round((currentTime- this.lastClockTime)/1000)*1000;
          this.lastClockTime = currentTime;
          //console.log(timePassed);
          this.timeLeft = this.timeLeft-timePassed;
          if (this.timeLeft <= 0) {
            this.timeLeft = 0;//Have to do this as it could be past 0 if in inactive tab
            clearInterval(this.intervalId);
            this.playAlarmSound();
          }
        }, 1000);
      }
    },

    pauseTimer() {
      if (!this.timerPaused) {
        clearInterval(this.intervalId); //pauses timer
        this.timerPaused = true;
      } else {
        this.startTimer("restart");
        this.timerPaused = false;
      }
    },

    stopTimer() {
      clearInterval(this.intervalId);
      
      if (this.audio){
          this.audio.pause();
          this.audio.currenttime = 0;
      } 
      this.timeLeft = this.initialTime;
      this.setTimeDefaults();
    },

    updateInitialTime(e, type) {
       //check typeof
       let inputValue = Number(e.target.value);
       //console.log(typeof inputValue);
       if(isNaN(inputValue)){
           alert('You must enter a valid number');
           if(type == "secs")
            e.target.value = this.timeLeftSeconds;
           else
            e.target.value = this.timeLeftMinutes;
           return;
       }

      
      let newTime = this.initialTime;

      if(!this.timerPaused){
        if (type == "secs") {
          this.initialTimeSeconds = e.target.value;
          //check if seconds is 60 or over
          if(this.initialTimeSeconds>=60){
            this.initialTimeSeconds = e.target.value%60;
           
            let newMins = Number(this.initialTimeMinutes)+Math.trunc(e.target.value/60);
             console.log(newMins);
            this.initialTimeMinutes = newMins;
          }

          newTime = this.initialTimeMinutes * 60 * 1000 + Number(this.initialTimeSeconds) * 1000;
        } else if (type == "mins") {
          this.initialTimeMinutes = e.target.value;
          newTime =
            (this.initialTimeSeconds * 1000) + (e.target.value * 60 * 1000);
        } else {
          console.log("couldn't update time");
        }

        this.initialTime = newTime;
      }

      if(this.timerPaused){
        if(type == "secs"){
           newTime = (this.timeLeftMinutes*60*1000) + e.target.value*1000;
        }
        else if (type== "mins")
           newTime = (this.timeLeftSeconds*1000) + (e.target.value*60*1000);

          if(newTime > this.initialTime){
            //this means the made the time larger than the intial time
            this.initialTime = newTime;
          }
          this.timeLeft = newTime;
        
      }
    },

    validateIsNumber(event){
      console.log("validating numbe");
      let keyCode = event.keyCode;
      if (keyCode < 48 || keyCode > 57) {
          event.preventDefault();
      }
    },

    playAlarmSound() {
     // console.log("playing sound");

      this.audio = new Audio(this.audioURL);
      this.audio.play();
      this.audio.onended = () => {
        if(this.audioRepeateTimes === 1)
          this.setTimeDefaults();
        else{
          this.audioRepeateTimes--;
          this.audio.play();
        }
      }; //listen to end of aduio event and reset state of timer running
    },

    setTimeDefaults(){
        //sets everythign back to default
        this.timerRunning = false;
        this.timerPaused = false;
        this.backgroundColor = 'blue';
        this.timeLeft = this.initialTime;
        this.audioRepeateTimes =3;
    }



  },

  computed: {
    percentageLeft() {
      let percentLeft = (this.timeLeft / this.initialTime) * 100;

      if(isNaN(percentLeft)){
        percentLeft = 0;
      }

      return percentLeft;
    },
    percentageLeftText(){
        const thePerentageLeft = Math.round(this.percentageLeft);
        if(thePerentageLeft== 0)
            return '';
        else{
            return thePerentageLeft+'%';
        }
    },
    timeLeftSeconds() {
      let val = (this.timeLeft / 1000) % 60;
      if(val.toString().length<2)
        val = "0"+val;
      return val;
    },
    timeLeftMinutes() {
      let val = Math.floor(this.timeLeft / 1000 / 60);
      if(val.toString().length<2)
        val = "0"+val;
      return val;
    },

    isInputDisabled(){
        return (this.timerRunning && !this.timerPaused)
    }
  },

  watch: {
    initialTime() {
      this.timeLeft = this.initialTime;
    },
    percentageLeft() {
      if (this.percentageLeft <= 50) this.backgroundColor = "orange";
      if (this.percentageLeft <= 25) this.backgroundColor = "red";
      if (this.percentageLeft > 50) this.backgroundColor = "blue";
    },
  },
};
</script>

<style scoped>

h1{
    font-size: 900%;
}

button{
    font-style: bold;
    margin: 5px;
    background-color:#44c767;
	border-radius:28px;
	border:1px solid #18ab29;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	padding:15px 31px;
	text-decoration:none;
	text-shadow:0px 1px 0px #2f6627;
}

button:hover {
	background-color:#5cbf2a;
}
button:active {
	position:relative;
	top:1px;
}

.timer {
  display: inline;
  margin: auto;
}


.visualTimerBorder {
  height: 50;
  width: 500px;
  border: 1px solid black;
  margin: auto;
}
.visualTimer {
  display: flex;
  background-color: blue;
  height: 50px;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .visualTimer{
    width: 80%;
  }

  .visualTimerBorder{
    width: 80%;
  }
}

.visualTimer .visualText{
    color: white;
    font-size: 1.5em;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-style: bold;
    justify-self: center;
    align-self: center;

}

.textTime {
  display: flex;
  margin: auto;
  width: 80pt;
  justify-content: center;
  font-size: 80pt;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-style: bold;
}
.timerText {
  float: left;
  border: none;
  width: fit-content;
}

.timeLabelMins{
  position:relative;
  left: 160px;
  top: 80pt;
  font-size: 20pt;
}

.timeLabelSecs{
  position:relative;
  right: 100px;
  top: 80pt;
  font-size: 20pt;
}



input{
  font-size: 80pt;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-style: bold;
}
input.timerTextSecs  {
  float: left;
  border: none;
  text-align: left;
  width:auto;
}

input.timerTextMins {
  float: left;
  border: none;
  width: auto;
  text-align: right;
 
}
</style>
