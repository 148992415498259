<template>
<div>
<h1>Simple Visual Timer</h1>
<h3>Created by <a href="https://www.makeshiftedtech.com">MakeShift EdTech</a></h3>
<simple-timer/>
</div>
</template>

<script>
import SimpleTimer from './components/SimpleTimer.vue'

export default {
  name: 'App',
  components:{
    SimpleTimer
  }
  
}
</script>

    
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

a {
  color:blue;
}
</style>
